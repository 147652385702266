var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-scrollbar", [
        _c(
          "div",
          [
            _c(
              "el-main",
              [
                _c(
                  "el-form",
                  {
                    ref: "formLayout",
                    staticClass: "demo-ruleForm form-container",
                    staticStyle: { "margin-top": "20px", width: "99%" },
                    attrs: {
                      model: _vm.dataForm,
                      rules: _vm.rules,
                      "label-width": "170px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "企业名称",
                                  prop: "companyName",
                                },
                              },
                              [
                                _c("template", { slot: "label" }, [
                                  _c("span", [_vm._v("企业名称")]),
                                ]),
                                _c("el-input", {
                                  attrs: {
                                    disabled: "true",
                                    "controls-position": "right",
                                    placeholder: "请输入企业名称",
                                  },
                                  model: {
                                    value: _vm.dataForm.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "companyName", $$v)
                                    },
                                    expression: "dataForm.companyName",
                                  },
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "统计月份",
                                  prop: "dateAndTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "month",
                                    format: "yyyy-MM",
                                    "value-format": "yyyy-MM",
                                    disabled: true,
                                  },
                                  on: { change: _vm.chooseDate },
                                  model: {
                                    value: _vm.dataForm.dateAndTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "dateAndTime", $$v)
                                    },
                                    expression: "dataForm.dateAndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "custom-row", attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "填报日期",
                                  prop: "updateTime",
                                },
                              },
                              [
                                _c("template", { slot: "label" }, [
                                  _c("span", [_vm._v("填报日期:")]),
                                ]),
                                _c("el-date-picker", {
                                  attrs: {
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    type: "date",
                                    disabled: "true",
                                    placeholder: "保存后自动生成",
                                  },
                                  model: {
                                    value: _vm.dataForm.updateTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "updateTime", $$v)
                                    },
                                    expression: "dataForm.updateTime",
                                  },
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "所属集团二级企业编码",
                                  prop: "parentUnitCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.dataForm.parentUnitCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "parentUnitCode",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.parentUnitCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "所属集团二级企业名称",
                                  prop: "parentCompanyName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.dataForm.parentCompanyName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "parentCompanyName",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.parentCompanyName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "事故发生起数",
                                  prop: "accCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.accCnt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "accCnt", $$v)
                                    },
                                    expression: "dataForm.accCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.dataForm.accCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "事故死亡人数",
                                  prop: "deathCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.deathCnt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "deathCnt", $$v)
                                    },
                                    expression: "dataForm.deathCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.dataForm.deathCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "事故重伤人数",
                                  prop: "seriousInjuryCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.seriousInjuryCnt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "seriousInjuryCnt",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.seriousInjuryCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.dataForm.seriousInjuryCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "事故轻伤人数",
                                  prop: "minorInjuryCnt",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "8",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.minorInjuryCnt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "minorInjuryCnt",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.minorInjuryCnt",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.dataForm.minorInjuryCntS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "直接经济损失（万元）",
                                  prop: "directEconomicLosses",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "10",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.directEconomicLosses,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "directEconomicLosses",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.directEconomicLosses",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        _vm.dataForm.directEconomicLossesS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "间接经济损失（万元）",
                                  prop: "indirectEconomicLosses",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 30px)" },
                                  attrs: {
                                    "controls-position": "right",
                                    maxlength: "10",
                                    "show-word-limit": "",
                                    readonly: _vm.type == "view",
                                  },
                                  model: {
                                    value: _vm.dataForm.indirectEconomicLosses,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "indirectEconomicLosses",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dataForm.indirectEconomicLosses",
                                  },
                                }),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        _vm.dataForm.indirectEconomicLossesS,
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-warning-outline iconLine",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _vm.type != "view"
            ? _c("el-button", { on: { click: _vm.headSave } }, [
                _vm._v("保 存"),
              ])
            : _vm._e(),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }